.payment {

  &__wrapper {
  }

  &__title {
    margin-bottom: 3rem;
  }

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__table-title {
    margin-bottom: 2.5rem;
    font-weight: 600;
    font-size: fluid-font(2rem, 1.8rem);
    line-height: 150%;
    text-transform: uppercase;
    color: var(--clr-default-700);
  }

  &__table {
    table {
      min-width: 100%;
      thead {
        th:first-child {
          min-width: 28rem;

          @media (max-width: em(992)) {
            min-width: 17rem;
          }
        }
      }

      @media (max-width: em(992)) {
        min-width: 76rem;
      }
    }
  }
}
