.info {
  &__wrapper {
    padding-bottom: 10rem;

    @media (max-width: em(992)) {
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 5rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__table {
    margin-bottom: initial;
    table {
      @media (max-width: em(575)) {
        min-width: 57rem;
      }
      tbody {
        td {
          p {
            width: 68rem;

            @media (max-width: em(992)) {
              width: initial;
            }
          }
        }
      }
    }
  }
}
