.live {

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: em(992)) {
      flex-direction: column;
      align-items: initial;
    }
  }

  &__content {
    margin-right: 3rem;
    max-width: 49rem;

    @media (max-width: em(992)) {
      margin-right: initial;
      margin-bottom: 5rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    min-width: 24rem;
  }

  &__image {
    position: relative;
    max-width: 70rem;

    .image__text {
      width: 48rem;
      transform: translate(-50%, -50%) rotate(-45deg);

      @media (max-width: em(1280)) {
        width: 35rem;
        transform: translate(-50%, -50%) rotate(0);
      }

      @media (max-width: em(992)) {
        &::before {
          filter: blur(6rem);
        }
      }

      @media (max-width: em(575)) {
        width: 32rem;
      }
    }

    @media (max-width: em(992)) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
