.license {

  &__wrapper {
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

