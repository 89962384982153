.registration {
  .container {
    background-color: var(--clr-primary-900);
  }

  &__wrapper {
    padding-bottom: 14rem;

    @media (max-width: em(992)) {
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 5rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__list {
    margin-bottom: 5rem;
  }

  &__item {
    display: flex;
    align-items: center;

    &:nth-child(even) {
      flex-direction: row-reverse;

      .registration__item-text {
        margin-right: initial;
        margin-left: 3rem;

        @media (max-width: em(992)) {
          margin-left: initial;
        }
      }

      @media (max-width: em(992)) {
        flex-direction: column;
      }
    }

    &:not(:last-child) {
      margin-bottom: 5rem;

      @media (max-width: em(575)) {
        margin-bottom: 3rem;
      }
    }

    @media (max-width: em(992)) {
      flex-direction: column;
    }
  }

  &__item-text {
    margin-right: 3rem;
    max-width: 59rem;

    @media (max-width: em(1200)) {
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial;
      max-width: initial;
    }
  }

  &__item-title {
    margin-bottom: 2rem;
    font-weight: 600;
    font-size: fluid-font(2rem, 1.8rem);
    text-transform: uppercase;
    color: var(--clr-default-700);
  }

  &__item-description {
    p:not(:last-child) {
      margin-bottom: 2rem;
    }

    @media (max-width: em(992)) {
      margin-bottom: 3rem;
    }
  }

  &__item-image {
    position: relative;
    max-width: 59rem;

    .image__text {
      top: initial;
      bottom: 2rem;
      min-width: 50rem;
      transform: translate(-50%, 0);

      @media (max-width: em(1200)) {
        min-width: 30rem;
        font-size: 4rem;
      }

      @media (max-width: em(767)) {
        font-size: fluid-font(4rem, 1.8rem);
      }

      &::before {
        display: none;
      }
    }
  }

  &__link {
  }
}
