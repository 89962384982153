.terms {
  .container {
    padding-top: 10rem;
    padding-bottom: 10rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      padding-top: 8rem;
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-top: 5rem;
      padding-bottom: 3rem;
    }
  }

  &__wrapper {
    position: relative;
    margin-left: -7rem;
    padding-top: 15rem;
    padding-right: 7rem;
    padding-bottom: 15rem;
    padding-left: 7rem;
    width: calc(100% + 14rem);
    background-image: -webkit-image-set(
      url(../img/terms/bg@2x.png) 2x,
      url(../img/terms/bg@1x.png) 1x
    );
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: em(1390)) {
      margin-left: -1.5rem;
      padding-top: 10rem;
      padding-right: 1.5rem;
      padding-bottom: 10rem;
      padding-left: 1.5rem;
      width: calc(100% + 3rem);
    }

    @media (max-width: em(992)) {
      padding-top: initial !important;
      padding-bottom: initial !important;
    }

    &:before, &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 10rem;
      background: linear-gradient(180deg, #292526 0%, rgba(41, 37, 38, 0) 100%);

      @media (max-width: em(1200)) {
        display: none;
      }
    }

    &:before {
      top: 0;
    }

    &::after {
      bottom: 0;
      transform: rotate(-180deg);
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__table {
    margin-bottom: initial;

    table {
      @media (max-width: em(575)) {
        min-width: 54rem;
      }
    }
  }
}
