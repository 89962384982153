.faq {

  &__wrapper {
  }

  &__title {
    margin-bottom: 3rem;
  }

  &__list {
  }

  &__item {
    border: 0.1rem solid var(--clr-primary-300);
    border-radius: 1.5rem;
    padding: 5rem;
    background-color: var(--clr-primary-800);

    &-title {
      margin-bottom: 2rem;
      font-weight: 600;
      font-size: fluid-font(2.4rem, 1.8rem);
      line-height: 150%;

      @media (max-width: em(575)) {
       margin-bottom: 1rem;
      }
    }

    &-text {
      line-height: 145%;
    }

    &:not(:last-child) {
      margin-bottom: 3rem;

      @media (max-width: em(575)) {
        margin-bottom: 2rem;
       }
    }

    @media (max-width: em(992)) {
      padding: 3rem;
    }

    @media (max-width: em(575)) {
      padding: 2rem;
    }
  }
}

