$burgerMQ: em(1100);

.menu {
  &__list {
    display: flex;
    align-items: center;

    @media (max-width: $burgerMQ) {
      flex-direction: column;
      margin: auto;
      padding-block: 4rem;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 2rem;

      @media (max-width: $burgerMQ) {
        margin-right: 0;
        margin-bottom: 3rem;
      }
    }

    a {
      font-weight: 600;
      line-height: 111%;
      color: var(--clr-default-800);
      transition: color var(--transition);

      @media (max-width: em(992)) {
        color: var(--clr-default-100);
      }

      @media (max-width: $burgerMQ) {
        font-size: 1.8rem;
      }
    }

    &:hover a {
      color: var(--clr-default-700);
      transition: color var(--transition);
    }
  }

  @media (max-width: $burgerMQ) {
    position: fixed;
    right: -100%;
    top: 0;
    z-index: 10;
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    max-width: 32rem;
    background-color: var(--clr-default-900);
    transition: right var(--transition);

    &--active {
      right: 0;
      transition: right var(--transition);
    }
  }
}
