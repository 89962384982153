.app {
  .container {
    padding-top: 10rem;
    padding-bottom: 10rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      padding-top: 8rem;
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: clip;
    margin-left: -7rem;
    padding-top: 25rem;
    padding-right: 7rem;
    padding-bottom: 20rem;
    padding-left: 7rem;
    width: calc(100% + 14rem);
    background-image: -webkit-image-set(url(../img/app/bg@2x.png) 2x, url(../img/app/bg@1x.png) 1x);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: em(1390)) {
      margin-left: -1.5rem;
      padding-top: 10rem;
      padding-right: 1.5rem;
      padding-bottom: 10rem;
      padding-left: 1.5rem;
      width: calc(100% + 3rem);
    }

    @media (max-width: em(992)) {
      flex-direction: column !important;
      padding-top: initial !important;
      padding-bottom: initial !important;
    }
  }

  &__content {
    max-width: 70rem;

    @media (max-width: em(1390)) {
      margin-right: 3rem;
    }

    @media (max-width: em(1200)) {
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial !important;
      margin-bottom: 5rem;
      max-width: initial;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__link {
    min-width: 24rem;
  }

  &__image {
    position: absolute;
    right: -38rem;
    top: -28rem;
    width: 88.5rem;
    height: 88.5rem;

    @media (max-width: em(1390)) {
      position: initial;
      right: initial;
      top: initial;
      width: initial;
      height: initial;
    }
  }
}
