.slots {

  .container {
    overflow-x: clip;
  }

  &__wrapper {
  }

  &__content-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20rem;

    @media (max-width: em(1390)) {
      margin-bottom: 5rem;
      padding-bottom: initial;
    }

    @media (max-width: em(992)) {
      flex-direction: column;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__content {
    max-width: 59rem;

    @media (max-width: em(1390)) {
      margin-right: 3rem;
      max-width: 50rem;
    }

    @media (max-width: em(992)) {
      margin-right: initial !important;
      margin-bottom: 5rem;
      max-width: initial !important;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    @media (max-width: em(575)) {
      margin-bottom: 3rem;
    }
  }

  &__link {
    min-width: 24rem;

    @media (max-width: em(992)) {
      min-width: 100%;
    }
  }

  &__image {
    position: absolute;
    right: -38rem;
    top: 0;
    overflow: hidden;
    height: 47rem;

    img {
      height: 100%;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 1rem;
      z-index: 1;
      border-radius: 1.5rem;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #292526 0%, rgba(41, 37, 38, 0) 100%);
      transform: rotate(180deg);
      pointer-events: none;
    }

    @media (max-width: em(1390)) {
      position: initial;
      right: initial;
      bottom: initial;
      overflow: initial;
      height: initial;
      max-width: 96rem;

      &::before {
        display: none;
      }

      img {
        height: auto;
      }
    }
  }

  &__table {
    margin-bottom: initial;

    table {
      thead {
        th {
          min-width: 32rem;

          @media (max-width: em(992)) {
            min-width: initial;
          }
        }
      }
      tbody {
        td:first-child {
          color: var(--clr-default-100);
        }
      }

      @media (max-width: em(575)) {
        min-width: 54rem;
      }
    }
  }
}
