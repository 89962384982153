.hero {

  .container {
    overflow: hidden;
    padding-top: 5rem;
    padding-bottom: 5rem;

    @media (max-width: em(1390)) {
      padding-top: initial;
      padding-bottom: initial;
    }
  }

  &__wrapper {
    padding-top: 7rem;
  }

  &__content-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6rem;

    @media (max-width: em(992)) {
      flex-direction: column-reverse;
      align-items: initial;
      margin-bottom: 3rem;
    }
  }

  &__image {
    position: absolute;
    left: -15rem;

    .image__text {
      width: 34rem;
    }

    @media (max-width: em(1390)) {
      position: relative;
      left: initial;
      margin-right: 3rem;
      max-width: 62.5rem;
    }

    @media (max-width: em(992)) {
      margin-right: auto !important;
      margin-left: auto;
    }
  }

  &__content {
    position: relative;
    left: -4rem;
    z-index: 1;
    margin-left: auto;
    max-width: 76rem;

    @media (max-width: em(1390)) {
      left: initial;
      margin-left: initial;
      max-width: 60rem;
    }

    @media (max-width: em(992)) {
      margin-bottom: 5rem;
      max-width: initial !important;
    }
  }

  &__title {
    margin-bottom: 5rem;
  }

  &__link {
    min-width: 49rem;

    @media (max-width: em(992)) {
      min-width: 100%;
    }
  }

  &__table {
    position: relative;
    z-index: 1;
    overflow: auto;
    margin-bottom: 3rem;

    table {
      overflow: hidden;
      border-collapse: collapse;
      border-radius: 1.5rem;
      min-width: 100%;
      background-color: var(--clr-primary-800);

      tr {
        border-bottom: 0.1rem solid var(--clr-default-300);
      }

      thead {
        th {
          vertical-align: top;
          padding-top: 4rem;
          padding-right: 3rem;
          padding-bottom: 2rem;
          padding-left: 3rem;
          font-weight: 400;
          line-height: 145%;
          text-align: start;
          color: var(--clr-primary-200);

          &:first-child {
            padding-left: 4rem;
            background-color: var(--clr-default-900);

            @media (max-width: em(992)) {
              padding-left: 2rem;
            }
          }

          @media (max-width: em(992)) {
            padding-top: 2rem;
            padding-right: 3rem;
            padding-bottom: 2rem;
            padding-left: 2rem;
          }
        }
      }

      tbody {
        td {
          vertical-align: top;
          padding-top: 2rem;
          padding-right: 3rem;
          padding-bottom: 2rem;
          padding-left: 3rem;

          &:first-child {
            padding-left: 4rem;
            color: var(--clr-default-700);
            background-color: var(--clr-default-900);

            @media (max-width: em(992)) {
              padding-left: 2rem;
            }
          }

          @media (max-width: em(992)) {
            padding-left: 2rem;
          }
        }
      }

      @media (max-width: em(575)) {
        min-width: 39rem;
      }
    }
  }

  &__benefits-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;

    @media (max-width: em(767)) {
      flex-direction: column;
    }
  }

  &__benefits-item {
    overflow: hidden;
    border-radius: 1.5rem;
    width: 50%;
    background-color: var(--clr-primary-800);

    &:first-child {
      margin-right: 3rem;

      @media (max-width: em(767)) {
        margin-right: initial;
        margin-bottom: 3rem;
      }
    }

    @media (max-width: em(767)) {
      width: initial;
    }
  }

  &__benefits-title {
    padding-top: 1rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    text-transform: uppercase;
    background-color: var(--clr-default-900);
  }

  &__item-list {
    padding-top: 2.5rem;
    padding-right: 3rem;
    padding-bottom: 6rem;
    padding-left: 3rem;

    @media (max-width: em(992)) {
      padding: 3rem;
    }
  }

  &__item {
    position: relative;
    padding-left: 2rem;
    font-weight: 500;
    line-height: 120%;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      background-color: var(--clr-list-400);
      transform: translateY(-50%);
    }

    &--cons {
      &::before {
        background-color: var(--clr-list-500);
      }
    }
  }

  &__table-payment {
    table {
      tbody {
        td {
          &:first-child {
            color: var(--clr-default-100);
          }
        }
      }
    }
  }

  &__text {
    margin-bottom: 5rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__link-bottom {
    margin-right: auto;
    margin-left: auto;
    min-width: 70rem;

    @media (max-width: em(992)) {
      width: 29rem;
      min-width: 100%;
      min-height: 7rem !important;
    }
  }
}
