.support {

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    a {
      color: var(--clr-default-700);
      transition: color var(--transition);

      @media (max-width: em(375)) {
        word-break: break-word;
      }

      &:hover {
        color: var(--clr-primary-500);
        transition: color var(--transition);
      }
    }
  }

  &__list {
    margin-bottom: 3rem;
  }

  &__item {
    position: relative;
    padding-left: 2rem;
    font-size: fluid-font(2.4rem, 1.8rem);
    line-height: 145%;

    a {
      color: var(--clr-default-700);
      transition: color var(--transition);

      &:hover {
        color: var(--clr-primary-500);
        transition: color var(--transition);
      }
    }

    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 50%;
      width: 0.5rem;
      height: 0.5rem;
      background-color: var(--clr-default-700);
      transform: translateY(-50%);
    }
  }
}

