.sale {

  &__wrapper {
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
