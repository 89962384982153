.resume {
  .container {
    overflow: hidden;
    padding-top: 10rem;

    @media (max-width: em(992)) {
      padding-top: 8rem;
    }

    @media (max-width: em(575)) {
      padding-top: 5rem;
    }
  }

  &__wrapper {
    position: relative;
    margin-left: -7rem;
    padding-top: 6rem;
    padding-right: 7rem;
    padding-bottom: 6rem;
    padding-left: 7rem;
    width: calc(100% + 14rem);
    background-image: -webkit-image-set(
      url(../img/resume/bg@2x.png) 2x,
      url(../img/resume/bg@1x.png) 1x
    );
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: em(1390)) {
      margin-left: -1.5rem;
      padding-right: 1.5rem;
      padding-left: 1.5rem;
      width: calc(100% + 3rem);
    }

    @media (max-width: em(992)) {
      padding-top: initial;
    }
  }

  &__text {
    margin-bottom: 3rem;

    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;

    @media (max-width: em(992)) {
      flex-direction: column;
      margin-bottom: 3rem;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 3rem;

      @media (max-width: em(992)) {
        margin-right: initial;
        margin-bottom: 5rem;
      }

      @media (max-width: em(767)) {
        margin-bottom: 3rem;
      }
    }
  }

  &__item-title {
    margin-bottom: 2.5rem;
    font-weight: 600;
    font-size: fluid-font(2rem, 1.8rem);
    color: var(--clr-default-700);
  }

  &__item-list {
    list-style: disc;
    padding-left: 2rem;
  }

  &__item-item {
    line-height: 145%;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__link {
  }
}
