.tournament {

  &__wrapper {
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__list {
    list-style: disc;
    margin-bottom: 2rem;
    padding-left: 2.5rem;
  }

  &__item {
    font-size: fluid-font(2.4rem, 1.8rem);
    line-height: 145%;
    color: var(--clr-default-700);

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
