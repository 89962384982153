.mobile {

  &__wrapper {
    position: relative;
    padding-bottom: 10rem;

    @media (max-width: em(992)) {
      padding-bottom: 8rem;
    }

    @media (max-width: em(575)) {
      padding-bottom: 5rem;
    }
  }

  &__content {
    max-width: 80rem;

    @media (max-width: em(1390)) {
      max-width: initial;
    }
  }

  &__info {
    &:not(:last-child) {
      margin-bottom: 10rem;

      @media (max-width: em(1390)) {
        margin-bottom: 5rem;
      }
    }
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__image {
    position: absolute;
    right: 0;
    top: 50%;
    width: 41rem;
    height: 59.5rem;
    transform: translateY(-50%);

    .image__text {
      width: 36rem;

      &:before {
        content: '';
        width: 35rem;
        height: 20rem;
        filter: blur(9rem);
      }
    }

    @media (max-width: em(1390)) {
      display: none;
    }
  }
}
